import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Page from '../components/page'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      seoSettings {
      title
      description
      openGraphImage {
        asset {
          url
          }
        }
      }
    }
  }
`

const PagePage = ({data: {sanityPage: page}}) => (
  <Layout>
    {page.seoSettings &&
      page.seoSettings.title &&
      page.seoSettings.description &&
      (
        <SEO
          title={page.seoSettings.title}
          description={page.seoSettings.description}
          ogimage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null}
        />
      )}
    <Page
      title={page.title}
      _rawBody={page._rawBody}
      mainImage={page.mainImage}
    />
  </Layout>
)

export default PagePage
