import React from 'react'
import Image from 'gatsby-image'
import PortableText from './portableText'
import Container from './container'

import styles from './page.module.css'

const Page = ({title, _rawBody, mainImage}) => {
  return (
    <article className={styles.root} >
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage} >
          <div className='grunge-edge first' />
          <Image fluid={mainImage.asset.fluid} alt={title} />
          <div className='grunge-edge bottom' />
        </div>
      )}
      <Container>
        <div>
          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </Container>
    </article>

  )
}
export default Page

// {project.thumbImage && project.thumbImage.filer(thumb => thumb.asset !== null).map(thumb => (
//   <div className="pf__tag" key={thumb}>
//     <img src={thumb.asset.fluid.src} alt={project.title} />
//   </div>
// ))}
